/**=====================
     Animation css start
==========================**/

@keyframes animate {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 0 500px;
    }
}

@keyframes loading {
    0% {
        border: 0 solid white;
        transition: all 0.3s ease;
    }

    20% {
        border: 8px solid white;
        width: 0;
        height: 0;
        transition: all 0.3s ease;
    }

    100% {
        border: 8px solid white;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

// order success page animation
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes grow {

    0%,
    100% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes shiny-btn1 {
    0% {
        transform: scale(0) rotate(45deg);
        opacity: 0;
    }

    80% {
        transform: scale(0) rotate(45deg);
        opacity: 0.5;
    }

    81% {
        transform: scale(4) rotate(45deg);
        opacity: 1;
    }

    100% {
        transform: scale(50) rotate(45deg);
        opacity: 0;
    }
}

@keyframes ring {
    0% {
        transform: rotateZ(0);
    }

    1% {
        transform: rotateZ(15deg);
    }

    3% {
        transform: rotateZ(-14deg);
    }

    5% {
        transform: rotateZ(17deg);
    }

    7% {
        transform: rotateZ(-16deg);
    }

    9% {
        transform: rotateZ(15deg);
    }

    11% {
        transform: rotateZ(-14deg);
    }

    13% {
        transform: rotateZ(13deg);
    }

    15% {
        transform: rotateZ(-12deg);
    }

    17% {
        transform: rotateZ(12deg);
    }

    19% {
        transform: rotateZ(-10deg);
    }

    21% {
        transform: rotateZ(9deg);
    }

    23% {
        transform: rotateZ(-8deg);
    }

    25% {
        transform: rotateZ(7deg);
    }

    27% {
        transform: rotateZ(-5deg);
    }

    29% {
        transform: rotateZ(5deg);
    }

    31% {
        transform: rotateZ(-4deg);
    }

    33% {
        transform: rotateZ(3deg);
    }

    35% {
        transform: rotateZ(-2deg);
    }

    37% {
        transform: rotateZ(1deg);
    }

    39% {
        transform: rotateZ(-1deg);
    }

    41% {
        transform: rotateZ(1deg);
    }

    43% {
        transform: rotateZ(0);
    }

    100% {
        transform: rotateZ(0);
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}


@keyframes heartbit {
    0% {
        transform: scale(0);
        opacity: 0
    }

    25% {
        transform: scale(0.1);
        opacity: .1
    }

    50% {
        transform: scale(0.5);
        opacity: .3
    }

    75% {
        transform: scale(0.8);
        opacity: .5
    }

    100% {
        transform: scale(1);
        opacity: 0
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}