@mixin size($size) {
  width: $size;
  height: $size;
}

// cyber monday modal
$noise-steps: 10;

@mixin randomNoise($name) {
  @keyframes #{$name} {
    @for $i from 0 through $noise-steps {
      #{percentage($i/$noise-steps)} {
        clip-path: inset(#{random(100) * 1%} 0 #{random(100) * 1%} 0);
      }
    }
  }
}

@include randomNoise(textNoise1);
@include randomNoise(textNoise2);

/*====== Padding css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .p-#{$i} {
    padding:#{$i}px;
  }
}

/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .p-l-#{$i} {
    padding-left:#{$i}px;
  }
}

/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .p-t-#{$i} {
    padding-top:#{$i}px !important;
  }
}

/*====== Padding-top css ends ======*/

/*====== Padding-bottom css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .p-b-#{$i} {
    padding-bottom:#{$i}px !important;
  }
}

/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .p-r-#{$i} {
    padding-right:#{$i}px;
  }
}

/*====== Padding-right css ends ======*/


/*====== Margin css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .m-#{$i} {
    margin:#{$i}px !important;
  }
}

/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .m-t-#{$i} {
    margin-top:#{$i}px !important;
  }
}

/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .m-b-#{$i} {
    margin-bottom:#{$i}px !important;
  }
}

/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .m-l-#{$i} {
    margin-left:#{$i}px !important;
  }
}

/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/
$i:0;

@while $i<=50 {
  $i: $i+5;

  .m-r-#{$i} {
    margin-right:#{$i}px;
  }
}

/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/
$i:0;

@while $i<=10 {
  $i: $i+1;

  .b-r-#{$i} {
    border-radius:#{$i}px !important;
  }
}

/*====== Border-radius css ends ======*/

/*====== Font-size css starts ======*/
$i:12;

@while $i<=100 {
  $i: $i+2;

  .f-#{$i} {
    font-size:#{$i}px;
  }
}

/*====== Font-size css ends ======*/

/*====== Font-weight css starts ======*/
$i:100,
300,
400,
600,
700,
900;

@each $val in $i {
  .f-w-#{$val} {
    font-weight: $val;
  }
}

/*====== Font-weight css ends ======*/

/*====== Font-style css starts ======*/
$i:normal,
italic,
oblique,
initial,
inherit;

@each $val in $i {
  .f-s-#{$val} {
    font-style: $val;
  }
}

/*====== Font-style css ends ======*/

/*====== Text-Decoration css starts ======*/
$i:overline,
line-through,
underline,
dashed,
blink,
dotted,
initial,
none,
solid,
wavy,
inherit,
double;

@each $val in $i {
  .text-#{$val} {
    text-decoration: $val;
  }
}

/*====== Text-Decoration css ends ======*/

/*====== Vertical-Align css starts ======*/
$i:baseline,
sub,
super,
top,
text-top,
middle,
bottom,
text-bottom,
initial,
inherit;

@each $val in $i {
  .#{$val} {
    vertical-align: $val;
  }
}

/*====== Vertical-Align css ends ======*/

/*====== Position css starts ======*/
$i:static,
absolute,
fixed,
relative,
initial,
inherit;

@each $val in $i {
  .p-#{$val} {
    position: $val;
  }
}

/*====== Position css ends ======*/

/*====== Float css starts ======*/
$i:left,
right,
none;

@each $val in $i {
  .f-#{$val} {
    float: $val;
  }
}

/*====== Float css ends ======*/

/*====== Overflow css starts ======*/
$i:hidden,
visible,
auto;

@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}

/*====== Overflow css ends ======*/

/*====== Image-sizes css starts ======*/
$i:10;

@while $i<=100 {
  $i: $i+10;

  .img-#{$i} {
    width:#{$i}px !important;
  }
}

/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/
$text-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$text-color : $theme-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $text-name {
  $i: index($text-name, $var);

  .font-#{$var} {
    color: nth($text-color, $i) !important;
  }
}

/*======= Text css ends ===========*/

/*======= Label-color css starts  ======= */
.label {
  border-radius: 2px;
  color: $white;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

$label-name:theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$label-color: $theme-color,
$theme-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $label-name {
  $i: index($label-name, $var);

  .label-#{$var} {
    background-color: nth($label-color, $i);
  }
}

/*======= Label-color css ends  ======= */

/*======= Badge-color css starts  ======= */
$badge-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$badge-color: $theme-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $badge-name {
  $i: index($badge-name, $var);

  .badge-#{$var} {
    background-color: nth($badge-color, $i);
  }
}

/*======= Badge-color css end  ======= */


/*======= Background-color css starts  ======= */
$background-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$background-color: $theme-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $background-name {
  $i: index($background-name, $var);

  .bg-#{$var} {
    background-color: nth($background-color, $i) !important;
    color: $white;
  }
}

/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */
$text-name:primary,
secondary,
success,
danger,
info,
light,
dark,
warning,
google-plus,
twitter,
linkedin,
fb;
$label-color:$theme-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
/*======= Font-color css end  ======= */


/*======= Button-color css starts  ======= */
// btn--color//
@each $btn-name,
$btn-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-#{$btn-name} {
    background-color: $btn-color !important;
    border-color: $btn-color !important;

    &.disabled {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }

    &:disabled {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($btn-color, 30%);
    }
  }
}

/*======= Button-color css ends  ======= */
@each $btn-name,
$btn-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-outline-#{$btn-name}-2x {
    border-width: 2px;
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
      box-shadow: none;
    }
  }
}

// outline buttons//
@each $btn-name,
$btn-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-outline-#{$btn-name} {
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;

    &.disabled {
      color: $btn-color;
    }

    @if($btn-name=="light") {
      color: $dark-color;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

//active button css //

@each $btn-name,
$btn-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-#{$btn-name}:not([disabled]):not(.disabled).active {
    background-color: darken($btn-color, 10%);
    border-color: darken($btn-color, 10%);
    box-shadow: none !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $white;
      background-color: darken($btn-color, 10%);
      border-color: darken($btn-color, 10%);
    }
  }
}

//* active button css end *//

@each $btn-name,
$btn-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {

  .btn-outline-#{$btn-name}-2x:not([disabled]):not(.disabled).active {
    background-color: $btn-color;
    border-color: $btn-color;
    box-shadow: none !important;
    color: $white;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $white;
      box-shadow: none !important;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

/*======= Table-Border-Bottom-color css starts  ======= */
$border-name:primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$border-color:$theme-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $border-name {
  $i: index($border-name, $var);

  table thead .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} td {
    border-bottom: 1px solid nth($border-color, $i);
  }
}

/*======= Table-Border-Bottom-color css ends  ======= */

/*======= Table styling css starts  ======= */

$table-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$table-color:$theme-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $table-name {
  $i: index($table-name, $var);

  .table-styling .table-#{$var},
  .table-styling.table-#{$var} {
    background-color: nth($border-color, $i);
    color: $white;
    border: 3px solid nth($border-color, $i);

    thead {
      background-color: darken(nth($border-color, $i), 10%);
      border: 3px solid darken(nth($border-color, $i), 10%);
    }
  }
}

/*======= Table styling css ends  ======= */

/*======= All-Borders-color css starts  ======= */
$b-name:primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$b-color:$theme-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $b-name {
  $i: index($b-name, $var);

  .b-#{$var} {
    border: 1px solid nth($b-color, $i) !important;
  }

  .b-t-#{$var} {
    border-top: 1px solid nth($b-color, $i) !important;
  }

  .b-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i) !important;
  }

  .b-l-#{$var} {
    border-left: 1px solid nth($b-color, $i) !important;
  }

  .b-r-#{$var} {
    border-right: 1px solid nth($b-color, $i) !important;
  }
}

/*======= All-Borders-color css ends  ======= */

/*====== Border width css starts ======*/
$i:1;

@while $i<=10 {
  $i: $i+1;

  .border-#{$i} {
    border-width:#{$i}px !important;
  }
}

/*====== Border width css ends ======*/

@each $shape-name,
$shape-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .order-shape-#{$shape-name} {
    background-color: $shape-color;
    width: 18px;
    height: 8px;
    margin-top: 7px;
    border-radius: 4px;
  }
}

@each $order-name,
$order-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .order-color-#{$order-name} {
    background-color: $order-color;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px;
  }
}

@mixin btn-gradien($deg, $leftcolor, $leftcolor-per, $rightcolor, $rightcolor-per, $font-color) {
  background-image: linear-gradient(to right, $leftcolor 0%, $rightcolor 51%, $leftcolor 100%);
  border: none;
  color: $font-color;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

@mixin btn-squre($border-radius) {
  border-radius: $border-radius+px;
}

@mixin btn-pill($border-radius) {
  border-radius: $border-radius+px;
}

@mixin btn-pill-first($radius) {
  border-radius: $radius+px 0 0 $radius+px;
}

@mixin btn-pill-last($radius) {
  border-radius: 0 $radius+px $radius+px 0;
}

// outline buttons//
@each $btn-name,
$btn-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-air-#{$btn-name} {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, .19) !important;

    &:hover,
    &:active,
    &:not([disabled]):not(.disabled):active {
      background-color: darken($btn-color, 10%);
      border-color: darken($btn-color, 10%);
    }
  }
}

// forms //
@each $btn-name,
$btn-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .input-air-#{$btn-name} {
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, .15) !important;

    &:focus {
      border-color: $btn-color;
    }
  }
}

//input background //
@each $btn-name,
$btn-color in (primary, $theme-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .form-bg-#{$btn-name} {
    background: $btn-color;
    border-color: $btn-color;

    @if($btn-name==light) {
      color: $dark-color;
    }

    @else {
      color: $white;
    }

    &:focus {
      border-color: $btn-color;
      background: $btn-color;

      @if($btn-name==light) {
        color: $dark-color;
      }

      @else {
        color: $white;
      }

      &:active {
        border-color: $btn-color;
        background: $btn-color;

        @if($btn-name==light) {
          color: $dark-color;
        }

        @else {
          color: $white;
        }
      }
    }
  }
}